.RecipesCategory>img {
    width: 20%;
    cursor: pointer;
}
.RecipesCategory.active {
    background-color: #ffcc00;
    /* Background color when active */
}

.EditIcon {
    position: absolute;
    top: -35px;
    right: -82px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: background-color 0.2s ease;
}

.DeleteIcon:hover {
    background-color: #ffebee;
}

.DeleteIcon {
    position: absolute;
    top: -5px;
    right: -72px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: background-color 0.2s ease;
}

.EditIcon:hover {
    background-color: #ffebee;
}

@media only screen and (max-width: 800px) {
    .RecipesCategory {
        display: flex;
        width: 40%;
    }
}